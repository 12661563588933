<template>
  <!-- <PopEntrada />  -->
  <Navbar />
  <BotonesContactoMovil />
  <Bannner class="banner" />

  <div class="home__contenido">
    <!-- --------------- NUESTRA HISTORIA --------------- -->
    <v-container class="bg-surface-variant home__nuestraHistoria col-sm-3">
      <v-row no-gutters>
        <!-- titulo -->
        <v-col cols="3" md="3" style="background-color: white;">
        </v-col>

        <v-col cols="6" md="6" style="background-color: white;">
          <v-sheet class="">
            <h1 >
              <center>{{ $t("home.historia.titulo") }}</center>
            </h1>
          </v-sheet>
        </v-col>

        <v-col cols="3" md="3" style="background-color: white;">
        </v-col>
        <!-- titulo -->

        <!-- texto y tortilla -->
        <v-col cols="1" md="2" style="background-color: white;">
        </v-col>

        <v-col cols="10" md="8" style="background-color: white; ">
          <v-sheet class="pa-1 ma-1 home__firstText">
            <p class="bold" >
              <center>{{ $t("home.historia.descripcion[0]") }}</center>
            </p>
            <p><strong>
                <center>{{ $t("home.historia.descripcion[1]") }}</center>
              </strong></p>
          </v-sheet>
        </v-col>

        <v-col cols="1" md="2">
          <v-sheet class="pa-1 ma-1 home__containerTortilla ">
            <img src="@/assets/home/image1.png" alt="tortilla" class="home__imgTortilla">
          </v-sheet>
        </v-col>
        <!-- texto y tortilla -->
      </v-row>
      <v-row no-gutters>
        <v-col cols="3">
          <v-sheet class="home__containerTaco">
            <img src="../assets/home/Pastor_orden1.png" class="home__imgTaco" alt="tacosAlPastor">
          </v-sheet>
        </v-col>

        <v-col cols="6">
          <v-sheet class="pa-1 ma-1">
            <br>
            <RouterLink to="/Historia">
              <center><button class="buttonmovil">{{ $t("home.historia.btnHistoria") }}</button></center>
            </RouterLink>
          </v-sheet>
        </v-col>

        <v-col cols="3">
        </v-col>
      </v-row>
    </v-container>
    <!-- --------------- NUESTRA HISTORIA --------------- -->
    <div class="cardContainer">
      <div style="display: flex; justify-content: center;" class="cardContainer__titulo">
        <img v-if="this.$i18n.locale == 'es'" src="@/assets/home/favoritos_titulo_es.png" alt="titulo_autenticos" class="">
        <img v-else src="@/assets/home/favoritos_titulo_en.png" alt="titulo_autenticos" class="">
      </div>
      <div>
        <img src="@/assets/home/fondo1.png" alt="ajoCebolla" class="cardContainer__img">
        <img src="@/assets/home/fondo2.png" alt="limón" class="cardContainer__img">
      </div>
      <FavoritosCocinaHome />
    </div>
    <!-- imagen parrillada-->
    <div class="parrillada">
      <div>
        <img v-if="this.$i18n.locale == 'es'" src="@/assets/home/farolitoEnTuEvento_es.png" style="align-items: center;" alt="titulo__FarolitoEvento">
        <img v-else class="farolitoEnTuEvento_en" src="@/assets/home/farolitoEnTuEvento_en.png" style="align-items: center;" alt="titulo__FarolitoEvento">
        <div style="text-align: center; align-items: left; padding-top: 2rem" v-html="$t('home.ftoEvento.descripcion')">
          
        </div>
        <router-link to="/Parrilladas">
          <button class="buttonmovil" @click="bluekai_infinity('FTO_Btn_infoparrilladas','Informacion_de_parrillada')">
            {{ $t('home.ftoEvento.btnParrillada')}}
          </button>
        </router-link>
      </div>
    </div>
  </div>

     <!-- FORMULARIO ENTERATE DE LO NUEVO -->
    <FormularioEnterate/>
    <!-- TERMINA FORMULARIO ENTERATE DE LO NUEVO -->
  
  <Footer />
</template>


<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Bannner from '@/components/Bannner.vue';
import PopEntrada from '@/components/PopEntrada.vue';
import FavoritosCocinaHome from '@/components/FavoritosCocinaHome.vue'
import Botonesbanner from '@/components/Botonesbanner.vue';
import BotonesContactoMovil from '@/components/BotonesContactoMovil.vue';
// import Bannner from '@/components/Bannner.vue';
import Datepicker from '@vuepic/vue-datepicker'; //datepicker
import '@vuepic/vue-datepicker/dist/main.css' //datepicker
import { ref } from 'vue'; //datepicker
import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';
import FormularioEnterate from '@/components/FormularioEnterate.vue';
import { useI18n } from 'vue-i18n';

export default {
  setup () {
    const { t } = useI18n;
    return { t };
  },
  name: 'Home',
  components: {
    Navbar, Footer, Bannner, FavoritosCocinaHome, Botonesbanner, BotonesContactoMovil, Datepicker, FormularioEnterate,PopEntrada
  },
  data() {
    return {
      date: null,
      language :  null,showPopup: true
    };
  },
  data: () => ({
    valid: true,
    name: '', apmaterno: '', appaterno: '',
    nameRules: [
      v => !!v || 'Dato requerido',
      v => (v && v.length <= 30) || 'No debe exceder de los caracteres',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail requerido',
      v => /.+@.+\..+/.test(v) || 'E-mail debe ser válido ',
    ],
    number: '',
    numberRules: [
      v => !!v || 'Dato requerido',
      v => ((v > 0 || v < 9)) || 'Debe ser un número',
      v => (v.length <= 6) || ' Deben ser menos de 6 digitos',
    ],

    select: null,
    items: [
      'Quejas',
      'Sugerencias',
      'Bolsa de trabajo',
    ],
    checkbox : false
  }

  ),

  methods: {
    async validate() {
      const { valid } = await this.$refs.form.validate()
      if (valid) alert('Formulario valido')
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    bluekai_infinity(key, value) {
      bk_addPageCtx(key, value);
      bk_doJSTag(93939, 1);
      let testMutation1 = function (msg) {
        //key               value
        msg.payload["FTO_Btn_infoparrilladas"] = "Informacion_de_parrillada";
        msg.payload["email_address"] = "daneroeda@gmail.com";
        msg.payload["button_id"] = "FTO_Btn_infoparrilladas";
        msg.payload["source"] = "FarolitoWeb";
        msg.payload["subtype"] = "Form Summited";
        msg.payload["target"] = "Form";
        msg.payload["type"] = "Summited";
        msg.payload["data.wt.es"] = "/Home";
      };
      ORA.analytics?.addMutation("myuniqueid", testMutation1);
      ORA.click();
    }
  },
  created() {
    bk_addPageCtx("__bk_l", "https://elfarolito.com.mx/");
    bk_doJSTag(93939, 1);
    let testMutation1 = function (msg) {
      //key               value
      msg.payload[""] = "https://elfarolito.com.mx/";
      msg.payload["email_address"] = "daneroeda@gmail.com";
      msg.payload["button_id"] = "";
      msg.payload["source"] = "FarolitoWeb";
      msg.payload["subtype"] = "Site Visited";
      msg.payload["target"] = "Site";
      msg.payload["type"] = "Visited";
      msg.payload["data.wt.es"] = "/Home";
    };
    ORA.analytics?.addMutation("myuniqueid", testMutation1);
    ORA.click()
    // if (localStorage.getItem("lang" == null)){
    //   localStorage.setItem("lang", "es");
    // }
  },
  mounted(){
    window.scrollTo(0, 0);
  }
}
</script>

<style  lang="scss">
.dp-custom-cell{
  border-radius: 50%;
  color:black;
  font-size: 1.3rem ; 
  //padding: 6px 12px;
}
//.dp__active_date { background: brown; }
//.dp__today { border: 1px solid brown; }
//.dp__select { color: #ad182d; }

//.dp-all { font-size: 2.5rem; padding: 6px 12px; }

//.dp__input {
//  background-color: rgb(0 0 0 / 0%);
//  border-radius: 10px;
//  font-family: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen, ubuntu, cantarell, "Open Sans", "Helvetica Neue", sans-serif;
//  border: 1px solid #212121;
//  outline: none;
//  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  //width: 98%;
  //font-size: 1.1rem;
  //line-height: 4.2rem;
  //padding: 6px 12px;
  //color: var(--dp-text-color);
  //box-sizing: border-box;
//}

</style>