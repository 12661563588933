import bannerWebQuesadillasPatrias from '@/assets/banners/es/FTO_Banner_Home_Quesadillas_Patrias.png'
import bannerWebKilos from '@/assets/banners/es/fto_bannerweb_kilos_2880x1145px.png'
import bannerFestivalHorchatas from '@/assets/banners/es/fto_banner_festival_horchatas.png'
// import bannerWebQuesabirria from '@/assets/banners/es/FTO_Banner_Web_Quesabirria.png'
// import bannerRoscaCalcetines from '@/assets/banners/es/fto_Banner-web-Kit-rosca-de-pastor-+-calcetines-2880x1145.png'
// import bannerColorinMango from '@/assets/banners/es/fto_bannerweb_colorin_mango_-2880x1145px.png'
import bannerLineaTaco from '@/assets/banners/es/fto_bannerweb_linea_taco_2880x1145px.png'
// import bannerVolcanes from '@/assets/banners/es/fto_banner_web_volcanes_2880x1145px.png'
// import bannerConsome from '@/assets/banners/es/fto_banner_web_promo_wa_consome_2880x1145.png'
// import bannerConsome from '@/assets/banners/es/fto_banner_web_promo_wa_consome_2880x1145.png'
// import bannerCasuelaCarnitas from '@/assets/banners/es/fto_banner_web_cazuela_carnitas_2880x1145px.png'
// import bannerSalsaPatron from '@/assets/banners/es/fto_banner_web_salsa_patron_2800x1145px.png'

const pathImgBanner = [
    {
        id: '1',
        imgEsp: bannerWebQuesadillasPatrias,
        imgEng: bannerWebQuesadillasPatrias
    },
    {
        id: '2',
        imgEsp: bannerWebKilos,
        imgEng: bannerWebKilos
    },
    {
        id: '3',
        imgEsp: bannerFestivalHorchatas,
        imgEng: bannerFestivalHorchatas
    },
    {
        id: '4',
        imgEsp: bannerLineaTaco,
        imgEng: bannerLineaTaco
    },
    // {
    //     id: '4',
    //     imgEsp: bannerCasuelaCarnitas,
    //     imgEng: bannerCasuelaCarnitas
    // },
    // {
    //     id: '5',
    //     imgEsp: bannerSalsaPatron,
    //     imgEng: bannerSalsaPatron
    // },
]

export default pathImgBanner